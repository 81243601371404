import axios from 'axios'
import { getCityCode } from '../cities'

function request(req) {
	const { city } = getCityCode()
	req.headers = { ...req.headers, city }
	return axios(req)
}

class Request {
	static get({ params = {}, url, headers = {}, responseType } = {}) {
		return request({
			method: 'GET',
			url,
			params,
			headers,
			responseType,
		})
	}

	static post({ data = {}, params = {}, url, headers = {}, responseType } = {}) {
		return request({
			method: 'POST',
			url,
			data,
			params,
			headers,
			responseType,
		})
	}

	static put({ data = {}, params = {}, url, headers = {}, responseType } = {}) {
		return request({
			method: 'PUT',
			url,
			data,
			params,
			headers,
			responseType,
		})
	}

	static patch({ data = {}, params = {}, url, headers = {}, responseType } = {}) {
		return request({
			method: 'PATCH',
			url,
			data,
			params,
			headers,
			responseType,
		})
	}

	static delete({ params = {}, url, headers = {}, responseType } = {}) {
		return request({
			method: 'DELETE',
			url,
			params,
			headers,
			responseType,
		})
	}
}

export default Request
