import { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import MainMenu from '../../components/MainMenu'
import { useAppContext } from '../../App'
import Icon from '../../components/Icon'
import Dialog from '../../components/Dialog'
import DesktopMenu from '../../components/DesktopMenu'
import { sortArrayLexicographically, MAX_VISIBLE_VOTED_IMAGES, generateImageSrcset, generateImageURL } from '../../utils'
import Strings from '../../strings'

import cx from 'classnames'
import './style.css'

const GlobalVoting = () => {
	const [loading, setLoading] = useState(true)
	const [imageLoadedNumber, setImageLoadedNumber] = useState(0)
	const [votedData, setVotedData] = useState([])
	const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
	const ctx = useAppContext()

	useEffect(() => {
		if (!ctx?.isVotingDataLoaded) {
			ctx.fetchVotingData()
		} else {
			let votedData = []
			for (const ogcFid in ctx?.votedData) {
				const currentData = ctx.votedData[ogcFid]

				for (const imageId in currentData.imagesVoting) {
					const url = currentData.imagesVoting[imageId].image_url
					const imageName = currentData.imagesVoting[imageId].image_name
					const imageGlobalVoteCreatedAt = currentData.imagesVoting[imageId].imageGlobalVoteCreatedAt
					const mark = currentData.imagesVoting[imageId].imageGlobalVote

					if (mark) {
						votedData.push({ imageId, ogcFid, imageName, url, mark, imageGlobalVoteCreatedAt })
					}
				}
			}

			setVotedData(votedData)
			setLoading(false)
		}
	}, [ctx?.isVotingDataLoaded, ctx?.isUserVotesLoaded])

	const getIconName = mark => {
		if (mark === 2) {
			return 'rating_2'
		}

		if (mark === 3) {
			return 'rating_3'
		}

		if (mark === 4) {
			return 'rating_4'
		}

		return 'rating_1'
	}

	const onLoadImage = () => {
		setImageLoadedNumber(previous => previous + 1)
	}

	const innerHeight = window.innerHeight
	const menuHeight = window.innerWidth > 440 ? 140 : 65
	const maxHeight = innerHeight - menuHeight

	const votedImages = sortArrayLexicographically(votedData, 'imageGlobalVoteCreatedAt', 'desc').slice(
		0,
		MAX_VISIBLE_VOTED_IMAGES,
	)

	const areImagesLoaded = imageLoadedNumber === votedImages.length

	const votesImagesCSS = cx('GlobalVoting__votesImages', { 'GlobalVoting__votesImages--visible': areImagesLoaded })

	return (
		<div className='GlobalVoting'>
			<div className='GlobalVoting__content'>
				<DesktopMenu />
				<div className='GlobalVoting__contentInner' style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
					<div className='GlobalVoting__firstPart'>
						<h1 className='GlobalVoting__mainTitle'>{Strings.getMessage('GlobalVoting.Title')}</h1>
						<h2 className='GlobalVoting__subtitle'>{Strings.getMessage('GlobalVoting.Description')}</h2>
						<div className='GlobalVoting__nav'>
							<Link to='../general-rating-photos' className='GlobalVoting__singleOption'>
								<Icon name='photos_voting' />
								<div>{Strings.getMessage('GlobalVoting.PhotosVoting')}</div>
							</Link>
							<Link to='../general-rating-map' className='GlobalVoting__singleOption'>
								<Icon name='map_voting' />
								<div>{Strings.getMessage('GlobalVoting.MapVoting')}</div>
							</Link>
						</div>
						<div className='GlobalVoting__order'>
							{Strings.getMessage('Or')}:{' '}
							<Link to='../classification-rating'>{Strings.getMessage('GlobalVoting.RateInDetail')}</Link>
						</div>
					</div>
					<div className='GlobalVoting__votesContainer'>
						{loading && <div className='GlobalVoting__votes'>{Strings.getMessage('Loading')}</div>}
						{!loading && (
							<>
								<h2 className='GlobalVoting__votingTitle'>{Strings.getMessage('GlobalVoting.OverallRating')}</h2>
								<div className='GlobalVoting__votes'>
									<div className='GlobalVoting__votesNumber'>{votedData.length}</div>
								</div>
							</>
						)}
						{!loading && !areImagesLoaded && <div>{Strings.getMessage('GlobalVoting.LoadingGeneralRatings')}</div>}
						{votedImages.length > 0 && (
							<div className={votesImagesCSS}>
								{votedImages.map(data => {
									return (
										<div key={data.imageId} className='GlobalVoting__singleImage'>
											<img
												srcSet={generateImageSrcset(data, 120)}
												src={generateImageURL(data, 120)}
												alt='The bike'
												width='100%'
												height='100%'
												onLoad={onLoadImage}
											/>
											<div className='GlobalVoting__imageMark'>
												<Icon name={getIconName(data.mark)} />
											</div>
										</div>
									)
								})}
							</div>
						)}
					</div>
					<Icon className='GlobalVoting__help' onClick={() => setIsHelpModalOpen(true)} name='help' />
					<Dialog open={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)}>
						<Fragment>
							<label className='GlobalVoting__modalLabel'>
								{Strings.getMessage('GlobalVoting.HelpModal.Subtitle')}
							</label>
							<h3 className='GlobalVoting__modalTitle'>{Strings.getMessage('GlobalVoting.HelpModal.Title')}</h3>
							<h4 className='GlobalVoting__modalSubtitle'>
								{Strings.getMessage('GlobalVoting.HelpModal.Heading1')}
							</h4>
							<p className='GlobalVoting__modalParagraph'>
								{Strings.getMessage('GlobalVoting.HelpModal.Paragraph1-1')}
							</p>
							<p className='GlobalVoting__modalParagraph'>
								{Strings.getMessage('GlobalVoting.HelpModal.Paragraph1-2')}
							</p>
							<h4 className='GlobalVoting__modalSubtitle'>
								{Strings.getMessage('GlobalVoting.HelpModal.Heading2')}
							</h4>
							<div>
								<p className='GlobalVoting__modalParagraph'>
									{Strings.getMessage('GlobalVoting.HelpModal.Paragraph2-1')}
								</p>
								<p className='GlobalVoting__modalParagraph'>
									{Strings.getMessage('GlobalVoting.HelpModal.List2Title')}
								</p>
								<ul className='GlobalVoting__modalParagraph'>
									<li>{Strings.getMessage('GlobalVoting.HelpModal.List2Option1')}</li>
									<li>{Strings.getMessage('GlobalVoting.HelpModal.List2Option2')}</li>
									<li>{Strings.getMessage('GlobalVoting.HelpModal.List2Option3')}</li>
								</ul>
								<p className='GlobalVoting__modalParagraph'>
									{Strings.getMessage('GlobalVoting.HelpModal.Paragraph2-2')}
								</p>
							</div>
						</Fragment>
					</Dialog>
				</div>
				<MainMenu />
			</div>
		</div>
	)
}

export default GlobalVoting
