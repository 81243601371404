import { Fragment } from 'react'
import english from './english'
import german from './german'

const Strings = (() => {
	let language = 'de'

	return {
		getLanguage: () => {
			return language
		},

		setLanguage: newLanguage => {
			language = newLanguage
		},

		getMessage: messageId => {
			let message
			switch (language) {
				case 'de':
					message = german[messageId]
				case 'en':
					message = english[messageId]
				default:
					message = german[messageId]
			}

			// Parse links
			const parts = message.split(/(\[.*?\]\(.*?\))/g)
			return parts.map((part, index) => {
				const matches = part.match(/^\[(.*?)\]\((.*?)\)$/)
				return (
					<Fragment key={index}>
						{matches ? (
							<a href={matches[2]} target='_blank' rel='noreferrer'>
								{matches[1]}
							</a>
						) : (
							part
						)}
					</Fragment>
				)
			})
		},
	}
})()

export default Strings
