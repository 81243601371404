import { createBrowserRouter, Navigate, redirect, RouterProvider } from 'react-router-dom'
import GlobalVoting from './pages/GlobalVoting'
import GlobalVotingPhotos from './pages/GlobalVotingPhotos'
import GlobalVotingMap from './pages/GlobalVotingMap'
import ClassificationVoting from './pages/ClassificationVoting'
import ClassificationVotingPhotos from './pages/ClassificationVotingPhotos'
import ClassificationVotingMap from './pages/ClassificationVotingMap'
import BikeIndex from './pages/BikeIndex'
import Menu from './pages/Menu'
import { getCityCode, IS_COUNTRY_DOMAIN } from './cities'
import localStorageService from './services/localStorageService'
import { refreshToken } from './api'
import Root from './pages/Root'

const prefix = IS_COUNTRY_DOMAIN ? '/:city' : '/:country/:city'
const { basePath } = getCityCode()

const protectedLoader = () => {
	if (!localStorageService.getUser()) {
		return redirect(basePath)
	}
	return null
}

const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		loader: async () => {
			const user = localStorageService.getUser()
			if (user && user.exp < Date.now()) {
				// Try to renew token if it has expired
				try {
					const response = await refreshToken(user.jwt)
					const token = response.data.token
					localStorageService.setUser({ jwt: token })
				} catch (err) {
					console.log(err)
					localStorageService.removeUser()
				}
			}
			return null
		},
		children: [
			{
				path: prefix,
				children: [
					{
						index: true,
						element: <BikeIndex />,
					},
					{
						path: 'menu',
						element: <Menu />,
					},
					{
						path: 'general-rating',
						element: <GlobalVoting />,
						loader: protectedLoader,
					},
					{
						path: 'general-rating-photos',
						element: <GlobalVotingPhotos />,
					},
					{
						path: 'general-rating-map',
						element: <GlobalVotingMap />,
						loader: protectedLoader,
					},
					{
						path: 'classification-rating',
						element: <ClassificationVoting />,
						loader: protectedLoader,
					},
					{
						path: 'classification-rating-photos',
						element: <ClassificationVotingPhotos />,
						loader: protectedLoader,
					},
					{
						path: 'classification-rating-map',
						element: <ClassificationVotingMap />,
						loader: protectedLoader,
					},
				],
			},
			{
				path: '*',
				element: <Navigate to={basePath} />,
			},
		],
	},
])

const Routes = () => <RouterProvider router={router} />

export default Routes
