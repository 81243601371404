import { useEffect, useState } from 'react'

import './style.css'

export const PlaceEditor = props => {
	const [newPlace, setNewPlace] = useState({ idle_id: '', name: '', enum: null })

	useEffect(() => {
		setNewPlace({
			idle_id: props.placeSelected.properties?.idle_id ?? '',
			name: props.placeSelected.properties?.name ?? '',
			enum: props.placeSelected.properties?.enum,
		})
	}, [props.placeSelected])

	const renderEditingActions = () => {
		return (
			<div className='placeEditor--editingActionsWrapper'>
				<div className='placeEditor--savePlaceInfo'>Möchten du diesen Ort als Favorit speichern?</div>
				<div>
					<input
						className='myRoutes--input'
						type='text'
						placeholder='Geben du einen Namen für diesen Ort ein'
						style={{ width: '100%' }}
						value={newPlace.name}
						onChange={evt => {
							setNewPlace({
								...newPlace,
								name: evt.target.value,
							})
						}}
					/>
				</div>
				<div>
					<div style={{ marginBottom: 4 }}>Art des Ortes</div>
					<select
						style={{ width: '100%' }}
						value={newPlace.idle_id}
						onChange={evt => {
							setNewPlace({
								...newPlace,
								idle_id: parseInt(evt.target.value),
							})
						}}>
						<option disabled value=''>
							Bitte auswählen
						</option>
						<option value='201'>Zuhause</option>
						<option value='202'>Arbeitsort</option>
						<option value='203'>Schule / Universität</option>
						<option disabled></option>
						<option value='204'>Café / Restaurant</option>
						<option value='205'>Einkauf Alltagsprodukte</option>
						<option value='206'>Freund:in / Verwandter</option>
						<option value='207'>Einkauf Nicht-Alltagsprodukte</option>
						<option value='208'>Kita / Vorschule / Schule</option>
						<option value='209'>Unterkunft (Hotel, AirBnB)</option>
						<option value='210'>Zahnarzt, Doktor, Spital</option>
						<option value='211'>Sport / Erholung</option>
						<option value='212'>Kultur (Kino, Theater, Museum)</option>
						<option value='213'>Anderer Bildungsort</option>
						<option value='214'>Anderer Freizeitort</option>
						<option value='215'>Sonstiger sozialer Besuch</option>
						<option value='216'>Sonstige Dienstleistung</option>
						<option disabled></option>
						<option value='300'>Haltestelle, Bahnhof (ÖV)</option>
						<option value='200'>Warteort / Treffpunkt</option>
					</select>
				</div>
				<div>
					<button
						onClick={() => {
							props.onSavePlace(newPlace)
						}}
						disabled={
							!newPlace.name ||
							!newPlace.idle_id ||
							(newPlace.name === props.placeSelected.properties.name &&
								newPlace.idle_id === props.placeSelected.properties.idle_id)
						}
						className='myRoutes--button'
						style={{ width: '100%' }}>
						Speichern
					</button>
				</div>
			</div>
		)
	}
	const renderRoutingActions = feature => {
		return (
			<div className='placeSearchResults--routingActionsWrapper'>
				<div className='placeSearchResults--routingButtonsWrapper'>
					{props.myRouteStops.start.features.length === 0 || props.startInputFocused.current === true ? (
						<button
							className='placeSearchResults--routingButton'
							onClick={() => {
								props.onSetAsStartPoint(feature)
							}}>
							Als Startpunkt verwenden
						</button>
					) : (
						''
					)}
					{props.myRouteStops.start.features.length > 0 && props.myRouteStops.destination.features.length > 0 ? (
						<button
							onClick={() => {
								props.onSetAsViaPoint(feature)
							}}
							className='placeSearchResults--routingButton'>
							Als Zwischenpunkt verwenden
						</button>
					) : (
						''
					)}
					<button
						onClick={() => {
							props.onSetAsEndPoint(feature)
						}}
						className='placeSearchResults--routingButton'>
						Als Zielpunkt verwenden
					</button>
				</div>
			</div>
		)
	}

	return (
		<div>
			<div className='placeSearchResults--placeEditorHeaderCloseWrapper'>
				<div className='placeSearchResults--placeEditorHeaderClose' onClick={() => props.onClose()}>
					<svg
						stroke='currentColor'
						fill='currentColor'
						strokeWidth='0'
						viewBox='0 0 512 512'
						height='16px'
						width='16px'>
						<path d='M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z'></path>
					</svg>
				</div>
				<div className='placeSearchResults--placeName'>{props.placeSelected.properties.name}</div>
				<div className='placeSearchResults--placeBorder'></div>
			</div>
			<div className='placeSearchResults--placeEditor'>
				<div className='placeSearchResults--placeHeaderWrapper'>
					<div className='placeSearchResults--placeEditorHeader'>
						<div className='placeSearchResults--placeEditorHeaderDetails'>
							{props.placeSelected.properties.full_address}
						</div>
					</div>
				</div>
			</div>
			{renderEditingActions()}
			{!props.isSavingUserRoute && renderRoutingActions(props.placeSelected)}
		</div>
	)
}
