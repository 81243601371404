import { useEffect, useState } from 'react'
import Map from '../components/Map'
import CircularIndeterminate from '../components/CircularIndeterminate'
import getAllUsersClassifiedEdges from '../api/getAllUsersClassifiedEdges'
import localStorageService from '../services/localStorageService'
import { getEdges, getImagesForVoting } from '../api'
import getPersona from '../api/getPresona'
import { useAppContext } from '../App'

const BikeIndex = () => {
	const { isUserLoggedIn } = useAppContext()
	const [data, setData] = useState(null)
	const [images, setImages] = useState(null)
	const [classifiedData, setClassifiedData] = useState(null)

	useEffect(() => {
		const userPresets = localStorageService.getUserPretestPanel()
		if (isUserLoggedIn && !userPresets) {
			// figure out if user has done survey but logging in via different browser
			getPersona()
				.then(data => data.data)
				.then(persona => {
					const { data } = persona
					if (data.length) {
						const dict = {}
						data.forEach(d => {
							if (!dict[d.topic]) {
								dict[d.topic] = {}
							}

							dict[d.topic] = { ...dict[d.topic], [d.key]: d.value }
						})
						localStorageService.setUserPretestPanel(dict)
					}
				})
				.catch(err => {
					console.log(err)
				})
		}

		getEdges(true).then(data => {
			data.features.forEach(d => {
				d.properties.line_width = d.properties.route_id ? 4 : 2
				d.properties.color = d.properties.route_id ? '#B3ACBD' : '#e7e7e7'

				// override settings for routes in planning
				d.properties.color = d.properties.status === 2 ? '#7b787f' : d.properties.color
				d.properties.line_width = d.properties.status === 2 ? 8 : d.properties.line_width
			})
			data.features = data.features.filter(d => {
				return d.properties.route_visible === 1
			})
			data.features.sort((a, b) => {
				return a.properties.status - b.properties.status
			})
			setData(data)
		})

		getAllUsersClassifiedEdges()
			.then(data => data.data)
			.then(data => setClassifiedData(data))

		if (isUserLoggedIn && localStorageService.isAdmin()) {
			getImagesForVoting()
				.then(data => data.data)
				.then(data => setImages(data))
		}
	}, [isUserLoggedIn])

	const handleUpdateData = data => {
		data.features.forEach(d => {
			d.properties.line_width = d.properties.route_id ? 4 : 2
			d.properties.color = d.properties.route_id ? '#B3ACBD' : '#e7e7e7'

			// override settings for routes in planning
			d.properties.color = d.properties.status === 2 ? '#7b787f' : d.properties.color
			d.properties.line_width = d.properties.status === 2 ? 8 : d.properties.line_width
		})
		data.features.sort((a, b) => {
			return a.properties.status - b.properties.status
		})

		setData(data)
	}

	if (!data || !classifiedData) {
		return <CircularIndeterminate />
	} else {
		return (
			<div className='App'>
				<Map updateData={handleUpdateData} data={data} classifiedData={classifiedData} images={images} />
			</div>
		)
	}
}

export default BikeIndex
