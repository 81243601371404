import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBikeRounded'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Icon from '../Icon'
import cx from 'classnames'
import Strings from '../../strings'
import { AVAILABLE_PATHS, getCityCode, TOP_LEVEL_DOMAIN } from '../../cities'
import { useAppContext } from '../../App'

import './style.css'

const DesktopMenu = ({ onBack, data, onNewVote }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const { clearUser, isUserLoggedIn, setIsUserDialogOpen } = useAppContext()
	const [searchParams, setSearchParams] = useSearchParams()
	const [classification, setClassification] = useState(0)
	const [submitting, setSubmitting] = useState(false)
	const { basePath } = getCityCode()

	const onBikeClicked = () => {
		navigate(basePath)
	}

	const onVotingClicked = () => {
		navigate(`${basePath}/${isUserLoggedIn ? 'general-rating' : 'general-rating-photos'}`)
	}

	const onMenuClicked = () => {
		navigate(`${basePath}/menu`)
	}

	useEffect(() => {
		const classification = searchParams.get('criterion')
		setClassification(classification)
	}, [])

	const onNewVotePressed = async mark => {
		if (onNewVote) {
			setSubmitting(true)
			await onNewVote(mark)
			setSubmitting(false)
		}
	}

	const renderNewVotingAction = () => {
		let badVoteLabel = ''
		let bestVoteLabel = ''
		let title = Strings.getMessage('GeneralRatingMap.HowDoYouRate')
		if (classification === 'safety') {
			badVoteLabel = Strings.getMessage('Unsure')
			bestVoteLabel = Strings.getMessage('Secure')
			title = Strings.getMessage('GeneralRatingMap.HowDoYouFeel')
		}

		if (classification === 'conflict') {
			badVoteLabel = Strings.getMessage('LotsOfConflicts')
			bestVoteLabel = Strings.getMessage('NoConflicts')
			title = Strings.getMessage('GeneralRatingMap.CanYouDrive')
		}

		if (classification === 'attractiveness') {
			badVoteLabel = Strings.getMessage('Unattractive')
			bestVoteLabel = Strings.getMessage('Attractive')
			title = Strings.getMessage('GeneralRatingMap.DoYouFind')
		}

		return (
			<div className='DesktopMenu__votingActions'>
				<div>
					<span className='DesktopMenu__segment'>
						{data?.features?.length}{' '}
						{data?.features.length > 1 ? Strings.getMessage('Segments') : Strings.getMessage('Segment')}{' '}
					</span>
					<span>{Strings.getMessage('Selected')}.</span>
				</div>
				<div className='DesktopMenu__votingContent'>
					<p className='DesktopMenu__votingText'>{title}</p>
					{!submitting && (
						<>
							<div className='DesktopMenu__votes'>
								<div onClick={onNewVotePressed.bind(null, 1)}>
									<Icon name='rating_1' />
								</div>
								<div onClick={onNewVotePressed.bind(null, 2)}>
									<Icon name='rating_2' />
								</div>
								<div onClick={onNewVotePressed.bind(null, 3)}>
									<Icon name='rating_3' />
								</div>
								<div onClick={onNewVotePressed.bind(null, 4)}>
									<Icon name='rating_4' />
								</div>
							</div>
						</>
					)}
					{submitting && <div className='DesktopMenu__voteSubmitting'>{Strings.getMessage('Submitting')}</div>}
					<div className='DesktopMenu__description'>
						<div className='DesktopMenu__description--bad'>{badVoteLabel}</div>
						<div className='DesktopMenu__description--good'>{bestVoteLabel}</div>
					</div>
				</div>
			</div>
		)
	}

	const renderMenuActions = () => {
		return (
			<div className='DesktopMenu__menuActions'>
				<a
					href={`https://velobserver.${TOP_LEVEL_DOMAIN}`}
					target='_blank'
					rel='noreferrer'
					className='DesktopMenu__menuActionSingleItem'>
					<Icon name='home' />
					<div className='Menu__itemText'>VelObserver.{TOP_LEVEL_DOMAIN}</div>
				</a>
			</div>
		)
	}

	const path = location.pathname
	const isRoot = path === basePath
	const isVoting =
		path.includes('/general-rating') ||
		path.includes('/general-rating-photos') ||
		path.includes('/general-rating-map') ||
		path.includes('/classification-rating') ||
		path.includes('/classification-rating-photos') ||
		path.includes('/classification-rating-map')
	const isMenu = path.includes('/menu')

	const bikeCSS = cx('mainMenu--icon-apply-space MenuIcon', {
		'MenuIcon--active': isRoot || AVAILABLE_PATHS.includes(path),
	})
	const bewertenCSS = cx('mainMenu--icon-apply-space MenuIcon', { 'MenuIcon--active': isVoting })
	const menuCSS = cx('mainMenu--icon-apply-space MenuIcon', { 'MenuIcon--active': isMenu })

	const featuresLength = data?.features?.length
	const hidePopup = isRoot
	const showAction = !!featuresLength && !hidePopup

	return (
		<div className='DesktopMenuWrapper'>
			<div className='DesktopMenuContainer'>
				{onBack && isUserLoggedIn ? (
					<Link className='DesktopMenu__topLink' to={onBack}>
						<Icon name='caret_left' /> <span>{Strings.getMessage('Overview')}</span>
					</Link>
				) : (
					<div className='DesktopMenu__logo'>
						<a href={`https://velobserver.${TOP_LEVEL_DOMAIN}${basePath}`} target='_blank' rel='noreferrer'>
							<img src='/images/logo.svg' alt='Logo' />
						</a>
					</div>
				)}
				<div className='DesktopMenu'>
					<div className={bikeCSS} onClick={onBikeClicked}>
						<DirectionsBikeIcon style={{ fontSize: 26 }} />
						<div className='DesktopMenu__label'>{Strings.getMessage('Route')}</div>
					</div>
					<div className={bewertenCSS} onClick={onVotingClicked}>
						<Icon name='bewerten' />
						<div className='DesktopMenu__label'>{Strings.getMessage('Evaluete')}</div>
					</div>
					<div className={menuCSS} onClick={onMenuClicked}>
						<MoreHorizIcon style={{ fontSize: 26 }} />
						<div className='DesktopMenu__label'>{Strings.getMessage('More')}</div>
					</div>
					{isUserLoggedIn ? (
						<div className='mainMenu--icon-apply-space MenuIcon' onClick={() => clearUser()}>
							<LogoutIcon style={{ fontSize: 26 }} />
							<div className='DesktopMenu__label'>{Strings.getMessage('Logout')}</div>
						</div>
					) : (
						<div className='mainMenu--icon-apply-space MenuIcon' onClick={() => setIsUserDialogOpen(true)}>
							<LoginIcon style={{ fontSize: 26 }} />
							<div className='DesktopMenu__label'>{Strings.getMessage('Register')}</div>
						</div>
					)}
				</div>
			</div>
			{showAction && renderNewVotingAction()}
			{isMenu && renderMenuActions()}
		</div>
	)
}

export default DesktopMenu
