import { Fragment, useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Strings from '../strings'
import { getCountries } from '../api'

const CssTextField = styled(TextField)({
	'& .MuiFormLabel-root': {
		fontSize: 12,
	},
	'& label.Mui-focused': {
		color: '#2d2047',
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: '#2d2047',
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#2d2047',
		},
		'&:hover fieldset': {
			borderColor: '#2d2047',
		},
		'&.Mui-focused': {
			borderColor: '#2d2047',
		},
	},
})

const defaultCountryCode = 'CH'

export default function PersonalData(props) {
	const [state, setState] = useState(props.data)
	const [countries, setCountries] = useState([])
	const [selectedCountry, setSelectedCountry] = useState(null)

	useEffect(() => {
		loadCountries()
	}, [])

	useEffect(() => {
		setSelectedCountry(countries?.find(country => country.code === state.country_code) ?? null)
	}, [countries, state.country_code])

	const loadCountries = async () => {
		const countryResponse = await getCountries(Strings.getLanguage())
		countryResponse.sort((a, b) => a.name?.localeCompare(b.name))
		setCountries(countryResponse)
		checkUserCountry()
	}

	const checkUserCountry = () => {
		fetch('https://extreme-ip-lookup.com/json/?key=R6rvbTSxEW2TbOHSjUtt')
			.then(res => res.json())
			.then(response => {
				setState({ ...state, country_code: response.countryCode })
				props.onChange({ ...state, country_code: response.countryCode })
			})
			.catch(() => {
				setState({ ...state, country_code: defaultCountryCode })
				props.onChange({ ...state, country_code: defaultCountryCode })
			})
	}

	return (
		<Fragment>
			<FormControl style={{ marginTop: 24 }} component='fieldset'>
				<FormLabel style={{ marginBottom: 8, color: '#444', fontFamily: 'DM Sans', fontSize: 14 }} component='legend'>
					{Strings.getMessage('Survey.PersonalInfo.Gender')}
				</FormLabel>
				<RadioGroup
					style={{ flexWrap: 'nowrap', fontSize: 12 }}
					onChange={e => {
						setState({ ...state, gender: e.target.value })
						props.onChange({ ...state, gender: e.target.value })
					}}
					row
					aria-label='gender'
					name='row-radio-buttons-group'>
					<FormControlLabel
						checked={state.gender === 'female' ? true : false}
						value='female'
						control={<Radio size={'small'} style={{ color: '#2d2047' }} />}
						label={
							<Typography style={{ fontFamily: 'DM Sans', fontSize: 12 }}>
								{Strings.getMessage('Survey.PersonalInfo.Femail')}
							</Typography>
						}
					/>
					<FormControlLabel
						checked={state.gender === 'male' ? true : false}
						value='male'
						control={<Radio size={'small'} style={{ color: '#2d2047' }} />}
						label={
							<Typography style={{ fontFamily: 'DM Sans', fontSize: 12 }}>
								{Strings.getMessage('Survey.PersonalInfo.Male')}
							</Typography>
						}
					/>
					<FormControlLabel
						checked={state.gender === 'other' ? true : false}
						value='other'
						control={<Radio size={'small'} style={{ color: '#2d2047' }} />}
						label={
							<Typography style={{ fontFamily: 'DM Sans', fontSize: 12 }}>
								{Strings.getMessage('Survey.PersonalInfo.Diverse')}
							</Typography>
						}
					/>
				</RadioGroup>
				<h3 style={{ fontSize: 14, fontWeight: 300, color: '#444', fontFamily: 'DM Sans', marginTop: 24, padding: 0 }}>
					{Strings.getMessage('Survey.PersonalInfo.Alter')}
				</h3>
				<CssTextField
					onChange={e => {
						setState({ ...state, birthyear: e.target.value })
						props.onChange({ ...state, birthyear: e.target.value })
					}}
					value={state.birthyear}
					label={Strings.getMessage('Survey.PersonalInfo.YearPlaceholder')}
					id='age-select'
					variant='standard'
				/>
			</FormControl>
			<Box style={{ marginTop: 24 }}>
				<h3 style={{ fontSize: 16, fontWeight: 300, color: '#444', fontFamily: 'DM Sans', marginBottom: 12, padding: 0 }}>
					{Strings.getMessage('Survey.PersonalInfo.Residence')}
				</h3>
				<Box style={{ display: 'flex' }}>
					<CssTextField
						value={state.postcode}
						onChange={e => {
							setState({ ...state, postcode: e.target.value })
							props.onChange({ ...state, postcode: e.target.value })
						}}
						style={{ marginRight: 12, flex: 1 }}
						id='postcode'
						label={Strings.getMessage('Survey.PersonalInfo.PLZ')}
						variant='standard'
					/>
					<CssTextField
						value={state.city}
						onChange={e => {
							setState({ ...state, city: e.target.value })
							props.onChange({ ...state, city: e.target.value })
						}}
						style={{ flex: 2 }}
						id='city'
						label={Strings.getMessage('Survey.PersonalInfo.Location')}
						variant='standard'
					/>
				</Box>
				<Box style={{ marginTop: 24, display: 'block' }}>
					<Autocomplete
						options={countries}
						getOptionLabel={option => option?.name ?? option?.code}
						value={selectedCountry}
						onChange={(event, value) => {
							setState({ ...state, country_code: value?.code ?? null })
							props.onChange({ ...state, country_code: value?.code ?? null })
						}}
						renderInput={params => (
							<CssTextField
								{...params}
								label={Strings.getMessage('Survey.PersonalInfo.Country')}
								variant='standard'
							/>
						)}
					/>
				</Box>
			</Box>
		</Fragment>
	)
}
