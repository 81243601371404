import { useEffect, useState } from 'react'
import MainMenu from '../../components/MainMenu'
import ImagesForVote from '../../components/ImagesForVote'
import { voteEdges } from '../../api'
import localStorageService from '../../services/localStorageService'
import { useAppContext } from '../../App'
import DesktopMenu from '../../components/DesktopMenu'
import { useSearchParams } from 'react-router-dom'
import Strings from '../../strings'

const CassificationVotingPhotos = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [classification, setClassification] = useState(0)
	const [dataForVote, setDataForVote] = useState([]) // list with the data for vote
	const [dataForVoteFetched, setDataForVoteFetched] = useState(false)
	const [votedDataNumber, setVotedDataNumber] = useState(0) // how many marks user gave (from index 0) for dataForVote
	const [isVotingFinished, setIsVotingFinished] = useState(false) // if there are no more data for vote
	const ctx = useAppContext()

	useEffect(() => {
		const classification = searchParams.get('criterion')
		setClassification(classification)
		window._paq.push(['trackEvent', 'Photos Voting', 'Detailed Photos Voting', classification]) // trackEvent: event category, event action, event name, event value (action and category required)
	}, [])

	useEffect(() => {
		if (!ctx?.isVotingDataLoaded) {
			ctx.fetchVotingData()
		} else {
			const classification = searchParams.get('criterion')

			const imagesVotingData = ctx?.imagesVotingData
			const votedData = ctx?.votedData
			let votedImagesIds = []

			for (const ogcFid in votedData) {
				for (const imageId in votedData[ogcFid].imagesVoting) {
					if (classification === 'safety' && votedData[ogcFid].imagesVoting[imageId].imageSafety) {
						votedImagesIds.push(+imageId)
					}

					if (classification === 'conflict' && votedData[ogcFid].imagesVoting[imageId].imageConflict) {
						votedImagesIds.push(+imageId)
					}

					if (classification === 'attractiveness' && votedData[ogcFid].imagesVoting[imageId].imageAttractiveness) {
						votedImagesIds.push(+imageId)
					}
				}
			}

			const dataForVote = imagesVotingData.filter(i => !votedImagesIds.includes(i.features[0].properties.image_id))
			setDataForVote(dataForVote)
			setIsVotingFinished(dataForVote.length === 0) // if array is emty there is nothing for vote
			setDataForVoteFetched(true)
		}
	}, [ctx?.isVotingDataLoaded, ctx?.isUserVotesLoaded])

	const updateSingleDataForVoteStreetName = (imageId, streetName) => {
		const newDataForVote = dataForVote.map(data => {
			if (data?.features[0]?.properties?.image_id === imageId) {
				const newFeatures = data.features.map(f => {
					return {
						...f,
						properties: {
							...f.properties,
							street_name: streetName,
						},
					}
				})
				return {
					...data,
					features: newFeatures,
				}
			}

			return data
		})

		setDataForVote(newDataForVote)
	}

	const onVote = async mark => {
		let updatingField

		if (classification === 'safety') {
			updatingField = 'safety'
		}

		if (classification === 'conflict') {
			updatingField = 'conflict'
		}

		if (classification === 'attractiveness') {
			updatingField = 'attractiveness'
		}

		const dataForSending = {
			type: 'FeatureCollection',
			features: dataForVote[votedDataNumber].features,
			properties: {
				user_id: localStorageService.getUser()?.user_id,
				updating_field: updatingField,
				updating_value: mark,
			},
		}

		const { data } = await voteEdges(dataForSending)
		if (data) {
			console.log('success', data)
			const updatingData = {
				mark,
				ogcFid: dataForVote[votedDataNumber].features[0].properties.ogc_fid,
				imageId: dataForVote[votedDataNumber].features[0].properties.image_id,
				imageName: dataForVote[votedDataNumber].features[0].properties.image_name,
				imageURL: dataForVote[votedDataNumber].features[0].properties.image_url,
			}

			if (classification === 'safety') {
				ctx.onSafetyImageVoted(updatingData)
			}

			if (classification === 'conflict') {
				ctx.onConflictImageVoted(updatingData)
			}

			if (classification === 'attractiveness') {
				ctx.onAttractivenessImageVoted(updatingData)
			}

			if (votedDataNumber + 1 === dataForVote.length) {
				setIsVotingFinished(true)
				return
			}

			setVotedDataNumber(votedDataNumber + 1)
		}
	}

	const onSkipImage = () => {
		setVotedDataNumber(votedDataNumber + 1)
	}

	let title = Strings.getMessage('Security')
	let badVoteLabel = Strings.getMessage('Unsure')
	let bestVoteLabel = Strings.getMessage('Secure')
	let text = Strings.getMessage('ImagesVoting.SecureQuestion')

	if (classification === 'conflict') {
		title = Strings.getMessage('FreedomFromConflict')
		badVoteLabel = Strings.getMessage('LotsOfConflicts')
		bestVoteLabel = Strings.getMessage('NoConflicts')
		text = Strings.getMessage('ImagesVoting.ConflictQuestions')
	}

	if (classification === 'attractiveness') {
		title = Strings.getMessage('Attractiveness')
		badVoteLabel = Strings.getMessage('Unattractive')
		bestVoteLabel = Strings.getMessage('Attractive')
		text = Strings.getMessage('ImagesVoting.AttractivenessQuestion')
	}

	const innerHeight = window.innerHeight
	const menuHeight = window.innerWidth > 440 ? 0 : 65
	const maxHeight = innerHeight - menuHeight

	const classificationRatingURL = '../classification-rating'

	return (
		<div>
			<DesktopMenu onBack={classificationRatingURL} />
			<div style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
				<ImagesForVote
					dataForVote={dataForVote}
					dataForVoteFetched={dataForVoteFetched}
					isVotingFinished={isVotingFinished}
					votedDataNumber={votedDataNumber}
					onVote={onVote}
					onSkipImage={onSkipImage}
					onBack={classificationRatingURL}
					title={title}
					badVoteLabel={badVoteLabel}
					bestVoteLabel={bestVoteLabel}
					text={text}
					updateSingleDataForVoteStreetName={updateSingleDataForVoteStreetName}
				/>
			</div>
			<MainMenu />
		</div>
	)
}

export default CassificationVotingPhotos
