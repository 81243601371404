import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import UserDialog from '../components/UserDialog'
import PretestDialog from '../components/PretestDialog'
import { getCityCode } from '../cities'
import App from '../App'

const Root = () => {
	const location = useLocation()
	const { basePath } = getCityCode()

	useEffect(() => {
		// If hash is provided in the URL in first render, scroll to the element
		// react-router-dom does not handle this automatically
		const hash = window.location.hash.slice(1)
		if (hash) {
			document.getElementById(hash)?.scrollIntoView({ behavior: 'smooth' })
		}
	}, [])

	return location.pathname === '/' ? (
		<Navigate to={basePath} replace />
	) : (
		<App>
			<Outlet />
			<UserDialog />
			<PretestDialog />
		</App>
	)
}

export default Root
