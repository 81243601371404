import React from 'react'
import cx from 'classnames'
import Strings from '../strings'

import './UsageChoice.css'

export const TRANSPORT_TYPES = [
	{
		id: 0,
		key: 'fussgaenger_freq',
		name: Strings.getMessage('Survey.HowOftenDoYouUse.OnFoot'),
	},
	{
		id: 1,
		key: 'velo_freq',
		name: Strings.getMessage('Survey.HowOftenDoYouUse.Bike'),
	},
	{
		id: 2,
		key: 'oev_freq',
		name: Strings.getMessage('Survey.HowOftenDoYouUse.PublicTransport'),
	},
	{
		id: 3,
		key: 'miv_freq',
		name: Strings.getMessage('Survey.HowOftenDoYouUse.OwnCar'),
	},
]

export const TRANSPORT_USAGE_TYPES = [
	{
		id: 0,
		key: 'never',
		name: Strings.getMessage('Survey.HowOftenDoYouUse.Never'),
	},
	{
		id: 1,
		key: 'irregular',
		name: Strings.getMessage('Survey.HowOftenDoYouUse.Rare'),
	},
	{
		id: 2,
		key: 'regularly',
		name: Strings.getMessage('Survey.HowOftenDoYouUse.Often'),
	},
	{
		id: 3,
		key: 'daily',
		name: Strings.getMessage('Survey.HowOftenDoYouUse.Daily'),
	},
]

const UsageChoice = ({ userUsageChoices, updateUsageChoice }) => {
	const onRadioButtonChanged = e => {
		const name = e.target.name
		const value = +e.target.value
		updateUsageChoice(name, value)
	}

	return (
		<div className='UsageChoice'>
			<div />
			<div className='UsageChoice__header UsageChoice__header--first'>{TRANSPORT_USAGE_TYPES[0].name}</div>
			<div className='UsageChoice__header UsageChoice__header--second'>{TRANSPORT_USAGE_TYPES[1].name}</div>
			<div className='UsageChoice__header UsageChoice__header--third'>{TRANSPORT_USAGE_TYPES[2].name}</div>
			<div className='UsageChoice__header UsageChoice__header--fourth'>{TRANSPORT_USAGE_TYPES[3].name}</div>

			{TRANSPORT_TYPES.map(transportType => {
				return (
					<React.Fragment key={`transportType - ${transportType.id}`}>
						<div className='UsageChoice_leftItems'>{transportType.name}</div>
						{TRANSPORT_USAGE_TYPES.map((transportUsageType, index) => {
							const isLast = index === TRANSPORT_USAGE_TYPES.length - 1
							const containerCSS = cx('UsageChoice__inputContainer', {
								'UsageChoice__inputContainer--last': isLast,
							})

							return (
								<React.Fragment key={`transportType - ${transportType.id} - ${transportUsageType.id}`}>
									<div className={containerCSS}>
										<input
											onChange={onRadioButtonChanged}
											type='radio'
											name={transportType.key}
											value={transportUsageType.id}
											checked={userUsageChoices[transportType.key] === transportUsageType.id}
										/>
									</div>
								</React.Fragment>
							)
						})}
					</React.Fragment>
				)
			})}
		</div>
	)
}

export default UsageChoice
