import CircularProgress from '@mui/material/CircularProgress'

import styles from './CircularIndeterminate.module.css'

const CircularIndeterminate = props => {
	return (
		<div className={styles.root}>
			<CircularProgress style={props.style ? props.style : {}} size={50} classes={{ circle: styles.circle }} />
		</div>
	)
}

export default CircularIndeterminate
