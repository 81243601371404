import { useState } from 'react'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'

export default function CustomRadioGroup(props) {
	const [value, setValue] = useState('')

	const handleChange = event => {
		setValue(event.target.value)
		props.onChange({ [event.target.value]: event.target.checked })
	}

	return (
		<Box style={{ marginLeft: 0, color: '#2d2047', fontFamily: 'DM Sans' }}>
			<FormControl component='fieldset' variant='standard'>
				<RadioGroup aria-label='gender' name='controlled-radio-buttons-group' value={value} onChange={handleChange}>
					{props.options &&
						props.options.length &&
						props.options.map(option => {
							return (
								<div style={{ marginBottom: 8 }} key={option.label + '_key'}>
									<FormControlLabel
										checked={props.init[option.name] ? true : false}
										key={option.name}
										value={option.name}
										control={<Radio size={'small'} style={{ color: '#2d2047' }} />}
										labelPlacement='end'
										label={
											<Typography style={{ fontFamily: 'DM Sans', fontSize: 12 }}>
												{option.label}
											</Typography>
										}
									/>
									{option.explanation ? (
										<div
											style={{
												fontSize: 9,
												color: 'rgba(0, 0, 0, 0.54)',
												margin: '0px 0 0 26px',
												padding: 0,
											}}>
											{option.explanation}
										</div>
									) : (
										''
									)}
								</div>
							)
						})}
				</RadioGroup>
			</FormControl>
		</Box>
	)
}
