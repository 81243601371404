import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MainMenu from '../../components/MainMenu'
import { useAppContext } from '../../App'
import Icon from '../../components/Icon'
import DesktopMenu from '../../components/DesktopMenu'
import Dialog from '../../components/Dialog'
import { sortArrayLexicographically, MAX_VISIBLE_VOTED_IMAGES, generateImageURL, generateImageSrcset } from '../../utils'
import Strings from '../../strings'
import cx from 'classnames'

import './style.css'

const ClassificationVoting = () => {
	const [isPhotoSelected, setIsPhotoSelected] = useState(true)
	const [loading, setLoading] = useState(true)
	const [imageLoadedNumber, setImageLoadedNumber] = useState(0)
	const [safetyVotedData, setSafetyVotedData] = useState([])
	const [conflictVotedData, setConflictVotedData] = useState([])
	const [attractivenessVotedData, setAttractivenessVotedData] = useState([])
	const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
	const ctx = useAppContext()

	useEffect(() => {
		if (!ctx?.isVotingDataLoaded) {
			ctx.fetchVotingData()
		} else {
			let safetyVotedData = []
			let conflictVotedData = []
			let attractivenessVotedData = []
			for (const ogcFid in ctx?.votedData) {
				const currentData = ctx.votedData[ogcFid]

				for (const imageId in currentData.imagesVoting) {
					const url = currentData.imagesVoting[imageId].image_url
					const imageName = currentData.imagesVoting[imageId].image_name
					const safety = currentData.imagesVoting[imageId].imageSafety
					const imageSafetyCreatedAt = currentData.imagesVoting[imageId].imageSafetyCreatedAt
					const conflict = currentData.imagesVoting[imageId].imageConflict
					const imageConflictCreatedAt = currentData.imagesVoting[imageId].imageConflictCreatedAt
					const attractiveness = currentData.imagesVoting[imageId].imageAttractiveness
					const imageAttractivenessCreatedAt = currentData.imagesVoting[imageId].imageAttractivenessCreatedAt

					if (safety) {
						safetyVotedData.push({ imageId, ogcFid, imageName, url, mark: safety, imageSafetyCreatedAt })
					}

					if (conflict) {
						conflictVotedData.push({ imageId, ogcFid, imageName, url, mark: conflict, imageConflictCreatedAt })
					}

					if (attractiveness) {
						attractivenessVotedData.push({
							imageId,
							ogcFid,
							imageName,
							url,
							mark: attractiveness,
							imageAttractivenessCreatedAt,
						})
					}
				}
			}

			setSafetyVotedData(safetyVotedData)
			setConflictVotedData(conflictVotedData)
			setAttractivenessVotedData(attractivenessVotedData)
			setLoading(false)
		}
	}, [ctx?.isVotingDataLoaded, ctx?.isUserVotesLoaded])

	const getIconName = mark => {
		if (mark === 2) {
			return 'rating_2'
		}

		if (mark === 3) {
			return 'rating_3'
		}

		if (mark === 4) {
			return 'rating_4'
		}

		return 'rating_1'
	}

	const onLoadImage = () => {
		setImageLoadedNumber(previous => previous + 1)
	}

	const photosCSS = cx('ClassificationVoting__switcherOption', {
		'ClassificationVoting__switcherOption--active': isPhotoSelected,
	})

	const mapCSS = cx('ClassificationVoting__switcherOption', {
		'ClassificationVoting__switcherOption--active': !isPhotoSelected,
	})

	const selectPhoto = () => {
		setIsPhotoSelected(true)
	}

	const selectMap = () => {
		setIsPhotoSelected(false)
	}

	const linkTo1 = isPhotoSelected
		? '../classification-rating-photos?criterion=safety'
		: '../classification-rating-map?criterion=safety'
	const linkTo2 = isPhotoSelected
		? '../classification-rating-photos?criterion=conflict'
		: '../classification-rating-map?criterion=conflict'
	const linkTo3 = isPhotoSelected
		? '../classification-rating-photos?criterion=attractiveness'
		: '../classification-rating-map?criterion=attractiveness'

	const innerHeight = window.innerHeight
	const menuHeight = window.innerWidth > 440 ? 140 : 65
	const maxHeight = innerHeight - menuHeight

	const safetyVotedImages = sortArrayLexicographically(safetyVotedData, 'imageSafetyCreatedAt', 'desc').slice(
		0,
		MAX_VISIBLE_VOTED_IMAGES,
	)

	const conflictVotedImages = sortArrayLexicographically(conflictVotedData, 'imageConflictCreatedAt', 'desc').slice(
		0,
		MAX_VISIBLE_VOTED_IMAGES,
	)

	const attractivenessVotedImages = sortArrayLexicographically(
		attractivenessVotedData,
		'imageAttractivenessCreatedAt',
		'desc',
	).slice(0, MAX_VISIBLE_VOTED_IMAGES)

	const areImagesLoaded =
		imageLoadedNumber === safetyVotedImages.length + conflictVotedImages.length + attractivenessVotedImages.length

	const votesImagesCSS = cx('ClassificationVoting__votesImages', {
		'ClassificationVoting__votesImages--visible': areImagesLoaded,
	})
	const votesImagesTitleCSS = cx('ClassificationVoting__classificationTitle', {
		'ClassificationVoting__classificationTitle--visible': areImagesLoaded,
	})

	return (
		<div className='ClassificationVoting'>
			<div className='ClassificationVoting__content'>
				<DesktopMenu />
				<div className='ClassificationVoting__contentInner' style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
					<div className='ClassificationVoting__firstPart'>
						<h1 className='ClassificationVoting__mainTitle'>{Strings.getMessage('ClassificationVoting.Title')}</h1>
						<h2 className='ClassificationVoting__subtitle'>
							{Strings.getMessage('ClassificationVoting.Description')}
						</h2>
						<div className='ClassificationVoting__switcher'>
							<div onClick={selectPhoto} className={photosCSS}>
								{Strings.getMessage('ClassificationVoting.PhotosVoting')}
							</div>
							<div onClick={selectMap} className={mapCSS}>
								{Strings.getMessage('ClassificationVoting.MapVoting')}
							</div>
						</div>
						<div className='ClassificationVoting__options'>
							<Link to={linkTo1} className='ClassificationVoting__singleOptions'>
								<div>
									<h3 className='ClassificationVoting__optionTitle'>{Strings.getMessage('Security')}</h3>
									<p className='ClassificationVoting__optionParagraph'>
										{Strings.getMessage('ClassificationVoting.SecurityDescription')}
									</p>
								</div>
								<img src='/images/criteria-safety.png' alt='Criteria Appeal' />
							</Link>
							<Link to={linkTo2} className='ClassificationVoting__singleOptions'>
								<div>
									<h3 className='ClassificationVoting__optionTitle'>
										{Strings.getMessage('FreedomFromConflict')}
									</h3>
									<p className='ClassificationVoting__optionParagraph'>
										{Strings.getMessage('ClassificationVoting.FreedomDescription')}
									</p>
								</div>
								<img src='/images/criteria-conflict.png' alt='Criteria Appeal' />
							</Link>
							<Link to={linkTo3} className='ClassificationVoting__singleOptions'>
								<div>
									<h3 className='ClassificationVoting__optionTitle'>{Strings.getMessage('Attractiveness')}</h3>
									<p className='ClassificationVoting__optionParagraph'>
										{Strings.getMessage('ClassificationVoting.AttractivenessDescription')}
									</p>
								</div>
								<img src='/images/criteria-appeal.png' alt='Criteria Appeal' />
							</Link>
						</div>
						<div className='ClassificationVoting__order'>
							{Strings.getMessage('Or')}:{' '}
							<Link to='../general-rating'>{Strings.getMessage('ClassificationVoting.SimplifiedAssessment')}</Link>
						</div>
					</div>
					<div>
						<div className='ClassificationVoting__votesContainer'>
							{loading && <div className='ClassificationVoting__votes'>{Strings.getMessage('Loading')}</div>}
							{!loading && (
								<>
									<h2 className='ClassificationVoting__votingTitle'>
										{Strings.getMessage('ClassificationVoting.AlreadyRated')}
									</h2>
									<div className='ClassificationVoting__votes'>
										<div>
											<div className='ClassificationVoting__votesNumber'>{safetyVotedData.length}</div>
											<div className='ClassificationVoting__votesDesc'>
												{Strings.getMessage('Security')}
											</div>
										</div>
										<div>
											<div className='ClassificationVoting__votesNumber'>{conflictVotedData.length}</div>
											<div className='ClassificationVoting__votesDesc'>
												{Strings.getMessage('FreedomFromConflict')}
											</div>
										</div>
										<div>
											<div className='ClassificationVoting__votesNumber'>
												{attractivenessVotedData.length}
											</div>
											<div className='ClassificationVoting__votesDesc'>
												{Strings.getMessage('Attractiveness')}
											</div>
										</div>
									</div>
								</>
							)}
						</div>
						{!loading && !areImagesLoaded && (
							<div>{Strings.getMessage('ClassificationVoting.LoadingClassificationRatings')}</div>
						)}
						{safetyVotedImages.length > 0 && (
							<>
								<div className={votesImagesTitleCSS}>{Strings.getMessage('Security')}</div>
								<div className={votesImagesCSS}>
									{safetyVotedImages.map(data => {
										return (
											<div key={data.imageId} className='ClassificationVoting__singleImage'>
												<img
													srcSet={generateImageSrcset(data, 120)}
													src={generateImageURL(data, 120)}
													alt='The bike'
													width='100%'
													height='100%'
													onLoad={onLoadImage}
												/>
												<div className='ClassificationVoting__imageMark'>
													<Icon name={getIconName(data.mark)} />
												</div>
											</div>
										)
									})}
								</div>
							</>
						)}
						{conflictVotedImages.length > 0 && (
							<>
								<div className={votesImagesTitleCSS}>{Strings.getMessage('FreedomFromConflict')}</div>
								<div className={votesImagesCSS}>
									{conflictVotedImages.map(data => {
										return (
											<div key={data.imageId} className='ClassificationVoting__singleImage'>
												<img
													srcSet={generateImageSrcset(data, 120)}
													src={generateImageURL(data, 120)}
													alt='The bike'
													width='100%'
													height='100%'
													onLoad={onLoadImage}
												/>
												<div className='ClassificationVoting__imageMark'>
													<Icon name={getIconName(data.mark)} />
												</div>
											</div>
										)
									})}
								</div>
							</>
						)}
						{attractivenessVotedImages.length > 0 && (
							<>
								<div className={votesImagesTitleCSS}>{Strings.getMessage('Attractiveness')}</div>
								<div className={votesImagesCSS}>
									{attractivenessVotedImages.map(data => {
										return (
											<div key={data.imageId} className='ClassificationVoting__singleImage'>
												<img
													srcSet={generateImageSrcset(data, 120)}
													src={generateImageURL(data, 120)}
													alt='The bike'
													width='100%'
													height='100%'
													onLoad={onLoadImage}
												/>
												<div className='ClassificationVoting__imageMark'>
													<Icon name={getIconName(data.mark)} />
												</div>
											</div>
										)
									})}
								</div>
							</>
						)}
					</div>
					<Icon className='ClassificationVoting__help' onClick={() => setIsHelpModalOpen(true)} name='help' />
					<Dialog open={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)}>
						<Fragment>
							<label className='ClassificationVoting__modalLabel'>
								{Strings.getMessage('ClassificationVoting.HelpModal.Subtitle')}
							</label>
							<h3 className='ClassificationVoting__modalTitle'>
								{Strings.getMessage('ClassificationVoting.HelpModal.Title')}
							</h3>
							<h4 className='ClassificationVoting__modalSubtitle'>
								{Strings.getMessage('ClassificationVoting.HelpModal.Heading1')}
							</h4>
							<p className='ClassificationVoting__modalParagraph'>
								{Strings.getMessage('ClassificationVoting.HelpModal.Paragraph1-1')}
							</p>
							<p className='ClassificationVoting__modalParagraph'>
								{Strings.getMessage('ClassificationVoting.HelpModal.Paragraph1-2')}
							</p>
							<h4 className='ClassificationVoting__modalSubtitle'>
								{Strings.getMessage('ClassificationVoting.HelpModal.Heading2')}
							</h4>
							<div>
								<p className='ClassificationVoting__modalParagraph'>
									{Strings.getMessage('ClassificationVoting.HelpModal.Paragraph2-1')}
								</p>
								<p className='ClassificationVoting__modalParagraph'>
									{Strings.getMessage('ClassificationVoting.HelpModal.List2Title')}
								</p>
								<ul className='ClassificationVoting__modalParagraph'>
									<li>{Strings.getMessage('ClassificationVoting.HelpModal.List2Option1')}</li>
									<li>{Strings.getMessage('ClassificationVoting.HelpModal.List2Option2')}</li>
									<li>{Strings.getMessage('ClassificationVoting.HelpModal.List2Option3')}</li>
								</ul>
								<p className='ClassificationVoting__modalParagraph'>
									{Strings.getMessage('ClassificationVoting.HelpModal.Paragraph2-2')}
								</p>
							</div>
						</Fragment>
					</Dialog>
				</div>
				<MainMenu />
			</div>
		</div>
	)
}

export default ClassificationVoting
