import { useRef, useEffect, useState } from 'react'
import { updateImageStreetName } from '../../api'
import mapboxgl from 'mapbox-gl'
import cx from 'classnames'
import { MINIMUM_LOADED_IMAGES } from '../ImagesForVote'
import { generateImageSrcset, generateImageURL } from '../../utils'
import { useAppContext } from '../../App'
import Icon from '../Icon'
import Input from '../Input'
import Button from '../Button'

import './style.css'

mapboxgl.accessToken = 'pk.eyJ1IjoidGVzc3Rpbmc1NjciLCJhIjoiY2twYmJwY2Z0MDV3cDJ5cnRtdTdkcmp2eCJ9.USaJNU9MX-pj5mihAORhMA'

const MapWidthImage = ({
	id,
	data,
	onImageLoaded,
	onImageHeightChanged,
	onMapLoaded,
	loading,
	updateSingleDataForVoteStreetName,
	isOnTop,
}) => {
	const { isAdmin } = useAppContext()
	const mapContainer = useRef()
	const [isMapVisible, setIsMapVisible] = useState(false)
	const [mapWidthImageHeight, setMapWidthImageHeight] = useState(window.innerWidth > 440 ? 533 : 250) // height for map and image (based on image height). it has to be specified because of map
	const imageURL = data?.features ? data?.features[0].properties?.image_url : ''
	const imageName = data?.features[0].properties?.image_name
	const imgData = { url: imageURL, imageName }

	const [isEditingStreetName, setIsEditingStreetName] = useState(false)
	const [editingStreetName, setEditingStreetName] = useState('')
	const [successMessage, setSuccessMessage] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	useEffect(() => {
		let map

		// Initialize map when this image is on top, if there are too many Mapbox instances rendered,
		// it will cause "WARNING: Too many active WebGL contexts. Oldest context will be lost." error
		// and map will not render correctly
		if (isOnTop && data.type) {
			const lat = data?.features[0]?.geometry?.coordinates[0][1]
			const lon = data?.features[0]?.geometry?.coordinates[0][0]

			map = new mapboxgl.Map({
				container: mapContainer.current,
				style: 'mapbox://styles/djra/ckx90pkh58k5d15p5066x6x7r',
				center: [lon, lat],
				zoom: 17,
				minZoom: 9,
			})

			map.on('load', () => {
				map.addSource('route-1', {
					type: 'geojson',
					data: data,
				})

				map.addLayer({
					id: `la-route-1`,
					type: 'line',
					source: 'route-1',
					layout: {
						'line-join': 'round',
						'line-cap': 'round',
					},
					paint: {
						'line-color': '#B4ACBF',
						'line-width': 7,
					},
				})

				if (onMapLoaded) {
					onMapLoaded()
				}
			})
		}

		// cleanup function to remove map on unmount
		return () => map && map.remove()
	}, [data, isOnTop])

	const resizeListener = () => {
		const image = document.getElementById(`map-image-${id}`)
		if (image?.offsetHeight > 0) {
			setMapWidthImageHeight(image?.offsetHeight)
			if (onImageHeightChanged) {
				onImageHeightChanged(image?.offsetHeight)
			}
		}
	}

	useEffect(() => {
		window.addEventListener('resize', resizeListener)
		resizeListener()

		return () => {
			window.removeEventListener('resize', resizeListener)
		}
	}, [])

	const switchView = () => {
		setIsMapVisible(!isMapVisible)
	}

	const onLoadImage = () => {
		resizeListener()
		if (onImageLoaded) {
			onImageLoaded(id)
		}
	}

	const onEditingStreetName = value => {
		setEditingStreetName(value)
		setErrorMessage('')
	}

	const onCancelEditingStreetName = () => {
		setIsEditingStreetName(false)
		setEditingStreetName('')
	}

	const onUpdateStreetName = async () => {
		const res = await updateImageStreetName({
			imageId: data?.features[0]?.properties?.image_id,
			street_name: editingStreetName,
		})

		if (res.error) {
			setErrorMessage(res.error)
			return
		}

		setSuccessMessage('Success')
		updateSingleDataForVoteStreetName(data?.features[0]?.properties?.image_id, editingStreetName)
		setTimeout(() => {
			setSuccessMessage('')
		}, 3000)
		onCancelEditingStreetName()
	}

	const addres = data?.features[0].properties?.street_name
	const city = data?.features[0].properties?.city
	const exif_timestamp = data?.features[0].properties?.exif_timestamp
	const exif_timestamp_offset = data?.features[0].properties?.exif_timestamp_offset
	let timeString = ''

	if (exif_timestamp) {
		// Add offset to UTC timestamp and use UTC timeZone option to get correct local date strings
		// regardless of current time zone of the client
		const imgDate = new Date(exif_timestamp + (exif_timestamp_offset ?? 0) * 60 * 60 * 1000)
		const weekday = imgDate.toLocaleDateString('de-DE', { weekday: 'long', timeZone: 'UTC' })
		const year = imgDate.toLocaleDateString('de-DE', { year: 'numeric', timeZone: 'UTC' })
		const month = imgDate.toLocaleDateString('de-DE', { month: 'long', timeZone: 'UTC' })
		const time = imgDate.toLocaleTimeString('de-DE', { hour: 'numeric', minute: '2-digit', timeZone: 'UTC' })

		timeString = weekday + ', ' + time + ' Uhr, ' + month + ' ' + year
	}

	const mapCSS = cx('mapboxgl-map', 'MapWidthImage__map', {
		'MapWidthImage--visible': isMapVisible,
		'MapWidthImage--hidden': !isMapVisible,
	})
	const imgCSS = cx('MapWidthImage__image', { 'MapWidthImage--visible': !isMapVisible, 'MapWidthImage--hidden': isMapVisible })
	const pinColor = isMapVisible ? '#56B6EA' : 'rgba(151,165,170,0.56)'

	const loadImage = id < MINIMUM_LOADED_IMAGES || !loading

	return (
		<div className='MapWidthImageContainer'>
			<div className='MapWidthImage' style={{ height: mapWidthImageHeight }}>
				<div className={mapCSS} ref={mapContainer} style={{ width: '100%', height: '100%' }} />
				{loadImage && (
					<img
						id={`map-image-${id}`}
						className={imgCSS}
						srcSet={generateImageSrcset(imgData, window.innerWidth > 440 ? 800 : 375)}
						src={generateImageURL(imgData, window.innerWidth > 440 ? 800 : 375)}
						alt='img'
						onLoad={onLoadImage}
					/>
				)}
				{!loadImage && <div className={imgCSS} />}
			</div>
			<div className='MapWidthImage__desc'>
				{(!!addres || !!timeString) && (
					<address>
						{!!addres && (
							<div className='MapWidthImage__address'>
								{!isEditingStreetName && (
									<>
										<span>
											{addres}, {city}
										</span>
										{isAdmin && <Icon name='pen' onClick={() => setIsEditingStreetName(true)} />}
										{successMessage && (
											<span className='MapWidthImage__addressSuccess'>{successMessage}</span>
										)}
									</>
								)}
								{isEditingStreetName && (
									<>
										<Input
											value={editingStreetName}
											onChange={onEditingStreetName}
											placeholder='Street Name'
										/>
										<Button
											className='MapWidthImage__addressCancelButton'
											onClick={onCancelEditingStreetName}>
											Cancel
										</Button>
										<Button onClick={onUpdateStreetName} className='MapWidthImage__addressSaveButton'>
											Save
										</Button>
										{errorMessage && <span className='MapWidthImage__addressError'>{errorMessage}</span>}
									</>
								)}
							</div>
						)}
						<div className='MapWidthImage__city'>{timeString}</div>
					</address>
				)}
				<Icon onClick={switchView} className='MapWidthImage__pin' name='pin' fill={pinColor} />
			</div>
		</div>
	)
}

export default MapWidthImage
