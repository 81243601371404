import mapboxgl from 'mapbox-gl'

export function validateEmail($email) {
	var emailReg = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
	return emailReg.test($email)
}

export function sortArrayLexicographically(arr, sortKey = null, order = 'asc') {
	const sign = order === 'asc' ? 1 : -1

	return arr.sort((a, b) => {
		const elementA = a[sortKey] || a
		const elementB = b[sortKey] || b

		if (elementA > elementB) {
			return 1 * sign
		} else if (elementA < elementB) {
			return -1 * sign
		} else {
			return 0
		}
	})
}

export const MAX_VISIBLE_VOTED_IMAGES = 30

export const generateImageURL = (data, width = 360) => {
	if (data.imageName) {
		return `https://velobserver.imgix.net/${data.imageName}?ar=3:2&fit=crop&w=${width}&auto=format&dpr=2`
	}

	return data.url
}

export const generateImageSrcset = (data, width = 360) => {
	if (data.imageName) {
		return `https://velobserver.imgix.net/${data.imageName}?ar=3:2&fit=crop&w=${width}&auto=format&dpr=1 1x, https://velobserver.imgix.net/${data.imageName}?ar=3:2&fit=crop&w=${width}&auto=format&dpr=2 2x`
	}

	return data.url
}

export const fitToFeatureBounds = (map, features) => {
	// Calculate bounds of features and focus map there
	// https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
	const mapBounds = features.reduce((bounds, feature) => {
		if (feature.geometry.type === 'Point') {
			bounds.extend(feature.geometry.coordinates, feature.geometry.coordinates)
		} else {
			feature.geometry.coordinates.forEach(coordinate => bounds.extend(coordinate, coordinate))
		}
		return bounds
	}, new mapboxgl.LngLatBounds())
	if (!mapBounds.isEmpty()) {
		map?.fitBounds(mapBounds, { padding: { top: 150, bottom: 150, left: 500, right: 50 } })
	}
}
