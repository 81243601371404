import { useState, useEffect } from 'react'
import Input from '../Input'
import Button from '../Button'
import { login } from '../../api'
import { validateEmail } from '../../utils'
import i18n from '../../i18n'
import Strings from '../../strings'
import { useAppContext } from '../../App'

import './style.css'

const Login = ({ setView }) => {
	const { updateUser } = useAppContext()
	const [isPosmo, setIsPosmo] = useState(false)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [errors, setErrors] = useState([])
	const [buttonDisabled, setButtonDisabled] = useState(false)

	useEffect(() => {
		if (email.trim()) {
			const newErr = errors.filter(e => !e.includes(i18n.getLang() === 'en' ? 'email' : 'E-Mail'))
			setErrors(newErr)
		}
	}, [email])

	useEffect(() => {
		if (password) {
			const newErr = errors.filter(e => !e.includes(i18n.getLang() === 'en' ? 'provide a password' : 'Passwort angeben'))
			setErrors(newErr)
		}
	}, [password])

	const onSubmit = async e => {
		e.preventDefault()
		const errors = []
		setErrors([])

		if (!email.trim()) {
			errors.push(i18n.translate(i18n.getMessages().MUST_PROVIDE_AN_EMAIL))
		}

		if (email.trim() && !validateEmail(email.trim())) {
			errors.push(i18n.translate(i18n.getMessages().EMAIL_IS_NOT_CORRECT))
		}

		if (!password) {
			errors.push(i18n.translate(i18n.getMessages().MUST_PROVIDE_A_PASSWORD))
		}

		if (errors.length > 0) {
			setErrors(errors)
			return
		}

		try {
			const dataForSending = {
				login_input: email.trim(),
				password,
			}

			setButtonDisabled(true)
			const req = await login(dataForSending)
			if (!req.data) {
				setErrors([i18n.translate(i18n.getMessage(req.messageId))])
				setButtonDisabled(false)
			} else {
				updateUser(req.data)
			}
		} catch (err) {
			setErrors([i18n.translate(i18n.getMessages().CONNECTED_TO_THE_INTERNET)])
			setButtonDisabled(false)
		}
	}

	return (
		<div className='LoginPage'>
			{isPosmo ? (
				<img className='LoginPage__logoPosmo' src='/images/posmo_logo.svg' alt='Logo' />
			) : (
				<img className='LoginPage__logo' src='/images/logo.svg' alt='Logo' />
			)}
			<h3 className='LoginPage__header'>{Strings.getMessage('Login.Title')}</h3>
			<p className='LoginPage__paragraph'>
				{!isPosmo ? Strings.getMessage('Login.Description--velobserver') : Strings.getMessage('Login.Description--posmo')}
			</p>
			<form onSubmit={onSubmit} className='LoginPage__form'>
				<Input
					type='email'
					value={email}
					onChange={setEmail}
					label={Strings.getMessage('Email')}
					placeholder='hallo@example.com'
				/>
				<Input type='password' value={password} onChange={setPassword} label={Strings.getMessage('Password')} />
				{errors.length > 0 && (
					<div className='LoginPage__errors'>
						{errors.map((e, index) => (
							<div key={`error-${index}`}>{e}</div>
						))}
					</div>
				)}
				<div className='LoginPage__buttons'>
					<Button type='submit' apple disabled={buttonDisabled}>
						{Strings.getMessage('Register')}
					</Button>
					<Button silver type='button' onClick={() => setView('forgotPassword')}>
						{Strings.getMessage('ForgotPassword')}
					</Button>
				</div>
			</form>
			<p className='LoginPage__paragraph2'>
				{Strings.getMessage('DontHaveAccount')}{' '}
				<a className='LoginPage__link' onClick={() => setView('signUp')}>
					{Strings.getMessage('CreateAccount')}
				</a>
			</p>
			<div className='LoginPage__separator' />
			{isPosmo ? (
				<div>
					<img className='LoginPage__logo2' src='/images/logo_silver.svg' alt='Logo' />
					<p className='LoginPage__paragraph'>{Strings.getMessage('Login.LoginAsVelobserver')}</p>
					<Button type='submit' apple onClick={() => setIsPosmo(false)}>
						{Strings.getMessage('Login.SwitchToVelobserver')}
					</Button>
				</div>
			) : (
				<div>
					<img src='/images/posmo_logo.svg' alt='Logo' />
					<p className='LoginPage__paragraph'>{Strings.getMessage('Login.LoginAsPosmo')}</p>
					<Button type='submit' apple onClick={() => setIsPosmo(true)}>
						{Strings.getMessage('Login.SwitchToPosmo')}
					</Button>
				</div>
			)}
		</div>
	)
}

export default Login
