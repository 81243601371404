import Icon from '../../components/Icon'
import MainMenu from '../../components/MainMenu'
import { useLocation } from 'react-router-dom'
import DesktopMenu from '../../components/DesktopMenu'
import Strings from '../../strings'
import { TOP_LEVEL_DOMAIN } from '../../cities'

import './style.css'

const Menu = () => {
	const location = useLocation()

	const copyToClipboard1 = () => {
		const host = window.location.origin
		const pathname = location.pathname
		const sufix = '#was-ist-velobserver'

		const url = `${host}${pathname}${sufix}`
		navigator.clipboard.writeText(url)
	}

	const copyToClipboard2 = () => {
		const host = window.location.origin
		const pathname = location.pathname
		const sufix = '#wie-funktioniert-die-bewertung'

		const url = `${host}${pathname}${sufix}`
		navigator.clipboard.writeText(url)
	}

	const copyToClipboard3 = () => {
		const host = window.location.origin
		const pathname = location.pathname
		const sufix = '#was-kann-ich-tun'

		const url = `${host}${pathname}${sufix}`
		navigator.clipboard.writeText(url)
	}

	const copyToClipboard4 = () => {
		const host = window.location.origin
		const pathname = location.pathname
		const sufix = '#was-bedeuten-die-kriterien'

		const url = `${host}${pathname}${sufix}`
		navigator.clipboard.writeText(url)
	}

	const copyToClipboard5 = () => {
		const host = window.location.origin
		const pathname = location.pathname
		const sufix = '#was-macht-ihr-mit-meinen-bewertungen'

		const url = `${host}${pathname}${sufix}`
		navigator.clipboard.writeText(url)
	}

	const copyToClipboard6 = () => {
		const host = window.location.origin
		const pathname = location.pathname
		const sufix = '#warum-soll-ich-weitere-fragen-uber-mich-beantworten'

		const url = `${host}${pathname}${sufix}`
		navigator.clipboard.writeText(url)
	}

	const copyToClipboard7 = () => {
		const host = window.location.origin
		const pathname = location.pathname
		const sufix = '#wer-steht-hinter-velobserver'

		const url = `${host}${pathname}${sufix}`
		navigator.clipboard.writeText(url)
	}

	const copyToClipboard8 = () => {
		const host = window.location.origin
		const pathname = location.pathname
		const sufix = '#was-kann-ich-tun-um-euch'

		const url = `${host}${pathname}${sufix}`
		navigator.clipboard.writeText(url)
	}

	return (
		<div>
			<div className='Menu'>
				<div className='Menu__content'>
					<DesktopMenu />
					<img className='Menu__logo' src='/images/logo.svg' alt='Logo' />
					<a
						href={`https://velobserver.${TOP_LEVEL_DOMAIN}`}
						target='_blank'
						rel='noreferrer'
						className='Menu__singleItem'>
						<Icon name='home' />
						<div className='Menu__itemText'>VelObserver.{TOP_LEVEL_DOMAIN}</div>
					</a>
					<div>
						<h1 className='Menu__title'>{Strings.getMessage('MenuPage.Title')}</h1>
						<ol className='Menu__mainList'>
							<li className='Menu__mainListItem'>
								<a href='#was-ist-velobserver'>{Strings.getMessage('MenuPage.Question1')}</a>
							</li>
							<li className='Menu__mainListItem'>
								<a href='#wie-funktioniert-die-bewertung'>{Strings.getMessage('MenuPage.Question2')}</a>
							</li>
							<li className='Menu__mainListItem'>
								<a href='#was-bedeuten-die-kriterien'>{Strings.getMessage('MenuPage.Question3')}</a>
							</li>
							<ul className='Menu__subList'>
								<li className='Menu__subListItem'>
									<a href='#sicherheit'>{Strings.getMessage('Security')}</a>
								</li>
								<li className='Menu__subListItem'>
									<a href='#konfliktfreiheit'>{Strings.getMessage('FreedomFromConflict')}</a>
								</li>
								<li className='Menu__subListItem'>
									<a href='#attraktivitat'>{Strings.getMessage('Attractiveness')}</a>
								</li>
							</ul>
							<li className='Menu__mainListItem'>
								<a href='#was-kann-ich-tun'>{Strings.getMessage('MenuPage.Question4')}</a>
							</li>
							<li className='Menu__mainListItem'>
								<a href='#was-macht-ihr-mit-meinen-bewertungen'>{Strings.getMessage('MenuPage.Question5')}</a>
							</li>
							<li className='Menu__mainListItem'>
								<a href='#warum-soll-ich-weitere-fragen-uber-mich-beantworten'>
									{Strings.getMessage('MenuPage.Question6')}
								</a>
							</li>
							<li className='Menu__mainListItem'>
								<a href='#wer-steht-hinter-velobserver'>{Strings.getMessage('MenuPage.Question7')}</a>
							</li>
							<li className='Menu__mainListItem'>
								<a href='#was-kann-ich-tun-um-euch'>{Strings.getMessage('MenuPage.Question8')}</a>
							</li>
						</ol>
					</div>
					<div>
						<div id='was-ist-velobserver' className='Menu__subtitle'>
							<h2>{Strings.getMessage('MenuPage.Question1')}</h2>
							<div onClick={copyToClipboard1} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className='Menu__description'>
							<p>{Strings.getMessage('MenuPage.Answer1')}</p>
						</div>

						<div id='wie-funktioniert-die-bewertung' className='Menu__subtitle'>
							<h2>{Strings.getMessage('MenuPage.Question2')}</h2>
							<div onClick={copyToClipboard2} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className='Menu__description'>
							<p>{Strings.getMessage('MenuPage.Answer2.1')}</p>
							<h3>{Strings.getMessage('MenuPage.Answer2.2')}</h3>
							<p>{Strings.getMessage('MenuPage.Answer2.3')}</p>
							<h3>{Strings.getMessage('MenuPage.Answer2.4')}</h3>
							<p>{Strings.getMessage('MenuPage.Answer2.5')}</p>
						</div>

						<div id='was-bedeuten-die-kriterien' className='Menu__subtitle'>
							<h2>{Strings.getMessage('MenuPage.Question3')}</h2>
							<div onClick={copyToClipboard4} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className='Menu__description Menu__description--withSubmenu'>
							<p>{Strings.getMessage('MenuPage.Answer3.1')}</p>
							<h3 id='sicherheit'>{Strings.getMessage('Security')}</h3>
							<p>{Strings.getMessage('MenuPage.Answer3.2')}</p>
							<h3 id='konfliktfreiheit'>{Strings.getMessage('FreedomFromConflict')}</h3>
							<p>{Strings.getMessage('MenuPage.Answer3.3')}</p>
							<h3 id='attraktivitat'>{Strings.getMessage('Attractiveness')}</h3>
							<p>{Strings.getMessage('MenuPage.Answer3.4')}</p>
						</div>

						<div id='was-kann-ich-tun' className='Menu__subtitle'>
							<h2>{Strings.getMessage('MenuPage.Question4')}</h2>
							<div onClick={copyToClipboard3} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className='Menu__description'>
							<p>{Strings.getMessage('MenuPage.Answer4')}</p>
						</div>

						<div id='was-macht-ihr-mit-meinen-bewertungen' className='Menu__subtitle'>
							<h2>{Strings.getMessage('MenuPage.Question5')}</h2>
							<div onClick={copyToClipboard5} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className='Menu__description'>
							<ol>
								<li>{Strings.getMessage('MenuPage.Answer5.1')}</li>
								<li>{Strings.getMessage('MenuPage.Answer5.2')}</li>
								<li>{Strings.getMessage('MenuPage.Answer5.3')}</li>
								<li>{Strings.getMessage('MenuPage.Answer5.4')}</li>
							</ol>
						</div>

						<div id='warum-soll-ich-weitere-fragen-uber-mich-beantworten' className='Menu__subtitle'>
							<h2>{Strings.getMessage('MenuPage.Question6')}</h2>
							<div onClick={copyToClipboard6} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className='Menu__description'>
							<p>{Strings.getMessage('MenuPage.Answer6')}</p>
						</div>

						<div id='wer-steht-hinter-velobserver' className='Menu__subtitle'>
							<h2>{Strings.getMessage('MenuPage.Question7')}</h2>
							<div onClick={copyToClipboard7} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className='Menu__description'>
							<p>{Strings.getMessage('MenuPage.Answer7')}</p>
						</div>

						<div id='was-kann-ich-tun-um-euch' className='Menu__subtitle'>
							<h2>{Strings.getMessage('MenuPage.Question8')}</h2>
							<div onClick={copyToClipboard8} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className='Menu__description'>
							<p>{Strings.getMessage('MenuPage.Answer8.1')}</p>
							<ol>
								<li>{Strings.getMessage('MenuPage.Answer8.2')}</li>
								<li>{Strings.getMessage('MenuPage.Answer8.3')}</li>
								<li>{Strings.getMessage('MenuPage.Answer8.4')}</li>
								<li>{Strings.getMessage('MenuPage.Answer8.5')}</li>
								<li>{Strings.getMessage('MenuPage.Answer8.6')}</li>
								<li>{Strings.getMessage('MenuPage.Answer8.7')}</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<MainMenu />
		</div>
	)
}

export default Menu
