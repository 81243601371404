import { useEffect, useState } from 'react'
import Dialog from './Dialog'
import { useAppContext } from '../App'
import PretestStepper from './PretestStepper'

const PretestDialog = () => {
	const { isUserLoggedIn, user } = useAppContext()
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		if (isUserLoggedIn && user?.isPretestCompleted === false) {
			setIsOpen(true)
		} else {
			setIsOpen(false)
		}
	}, [isUserLoggedIn, user?.isPretestCompleted])

	return (
		<Dialog open={isOpen} allowClose={false} fullScreen={true}>
			<PretestStepper />
		</Dialog>
	)
}

export default PretestDialog
