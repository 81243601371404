import './style.css'
import { getPlace } from '../../api'
import { PlaceEditor } from '../PlaceEditor'

const PlaceSearchResults = props => {
	const handleOnPlaceSave = async newPlace => {
		const place = (
			await getPlace({
				lat: props.placeSelected.geometry.coordinates[1],
				lon: props.placeSelected.geometry.coordinates[0],
			})
		).data

		const { city, country, country_code, house_number, postcode, street } = place
		props.onSavePlace({
			node: {
				name: newPlace.name || props.placeSelected.properties.name,
				alias_name: newPlace.name,
				idle_id: newPlace.idle_id,
				user_added: true,
				lat: props.placeSelected.geometry.coordinates[1],
				lon: props.placeSelected.geometry.coordinates[0],
				postcode: postcode,
				house_number: house_number,
				street: street,
				city: city,
				country: country,
				country_code: country_code,
			},
			enum: newPlace.enum,
		})
	}

	const renderPlaceList = () => {
		return props.placeList.features
			.sort((a, b) => {
				// in case ther is idle_id we want to show then first
				if (a.properties.idle_id && !b.properties.idle_id) {
					return -1
				}
				if (!a.properties.idle_id && b.properties.idle_id) {
					return 1
				}
				if (a.properties.idle_id && b.properties.idle_id) {
					return a.properties.idle_id - b.properties.idle_id
				}

				return a.properties.name.localeCompare(b.properties.name)
			})
			.map((feature, index) => {
				return (
					<div key={`place-${index}`}>
						<div className='placeSearchResults--placeWrapper'>
							<div
								className='placeSearchResults--placeInnerWrapper'
								onClick={() => {
									props.onPlaceClicked(feature)
								}}>
								<div className='placeSearchResults--icon'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										height='20px'
										viewBox='0 0 24 24'
										width='20px'
										fill={feature.properties.idle_id ? '#84b200' : '#a1a2a1'}>
										<path d='M0 0h24v24H0z' fill='none' />
										<path d='M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z' />
									</svg>
								</div>
								<div className='placeSearchResults--place'>
									<div className='placeSearchResults--placeName'>{feature.properties.name}</div>
									<div className='placeSearchResults--placeAddress'>{feature.properties.full_address}</div>
								</div>
							</div>
						</div>
					</div>
				)
			})
	}

	return (
		<div className='placeSearchResults--placeList'>
			{props.placeSelected && (
				<PlaceEditor
					startInputFocused={props.startInputFocused}
					onSavePlace={newPlace => {
						handleOnPlaceSave(newPlace)
					}}
					onClose={() => {
						props.setPlaceSelected(null)
						props.clearMySelectedPlace()
						props.setIsSavingUserRoute(false)
					}}
					placeSelected={props.placeSelected}
					myRouteStops={props.myRouteStops}
					onSetAsStartPoint={props.onSetAsStartPoint}
					onSetAsEndPoint={props.onSetAsEndPoint}
					onSetAsViaPoint={props.onSetAsViaPoint}
					isSavingUserRoute={props.isSavingUserRoute}
				/>
			)}
			{!props.placeSelected && renderPlaceList()}
		</div>
	)
}

export default PlaceSearchResults
