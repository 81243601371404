import { useState } from 'react'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import Strings from '../strings'

export default function MultipleOptions(props) {
	const initProps = {}

	props.options.forEach(option => {
		initProps[option.name] = props.init[option.name] || false
	})

	const [state, setState] = useState(initProps)

	const handleChange = event => {
		setState({
			...state,
			[event.target.name]: event.target.checked,
		})

		props.onChange({ ...state, [event.target.name]: event.target.checked })
	}

	return (
		<FormControl component='fieldset' variant='standard' style={{ margin: '16px 0' }}>
			<FormHelperText style={{ fontSize: 9 }}>{Strings.getMessage('MultipleEntriesPossible')}</FormHelperText>
			<FormGroup>
				{props.options &&
					props.options.length &&
					props.options.map(option => {
						return (
							<FormControlLabel
								key={option.name}
								control={
									<Checkbox
										style={{
											color: '#2d2047',
											fontSize: 12,
										}}
										size={'small'}
										color={'primary'}
										checked={state[option.name]}
										onChange={handleChange}
										name={option.name}
									/>
								}
								label={<Typography style={{ fontFamily: 'DM Sans', fontSize: 12 }}>{option.label}</Typography>}
							/>
						)
					})}
			</FormGroup>
		</FormControl>
	)
}
